import { captureException } from '../'
import { SEND_REFERRAL_EMAIL } from '../../graphql/mutations/referrals'
import { getApolloClient } from '../../utils'

export const enum ReferralType {
    Default = 'default',
    Challenge = 'challenge'
}
export interface ReferralEmailRequestBody {
    emailAddresses: string[]
    coworkerReferralLink: string
    friendsAndFamilyReferralLink: string
    clientId: string
    companyId: string
    allowExistingUsers?: boolean
    referralType?: ReferralType
}

export const enum SendReferralEmailResultType {
    Success = 'success',
    Error = 'error'
}

export interface SendReferralEmailResponseData {
    alreadyUsers: string[]
    failedToSendEmailTo: string[]
}

export interface SendReferralEmailResult {
    status: SendReferralEmailResultType
    error?: string
    data?: SendReferralEmailResponseData
}

export const sendReferralEmail = async (
    body: ReferralEmailRequestBody
): Promise<SendReferralEmailResult> => {
    try {
        try {
            const client = getApolloClient()
            const response = await client.mutate({
                variables: {
                    input: body
                },
                mutation: SEND_REFERRAL_EMAIL
            })
            const result = response?.data?.identity?.user?.sendReferralEmails
            return {
                status: SendReferralEmailResultType.Success,
                data: {
                    alreadyUsers: result?.alreadyUsers,
                    failedToSendEmailTo: result?.failedToSendEmailTo
                }
            }
        } catch (err) {
            const error = new Error('Error sending referral email')
            captureException(
                error,
                { message: `Status: ${err.status}` },
                'identity'
            )
            return {
                status: SendReferralEmailResultType.Error,
                error: error.message
            }
        }
    } catch (error) {
        return {
            status: SendReferralEmailResultType.Error,
            error: error.message
        }
    }
}
