import achievement from './achievement'
import announcement from './announcement'
import auth from './auth'
import breadcrumbs from './breadcrumbs'
import garden from './garden'
import globalAppVariables from './globalAppVariables'
import hybrid from './hybrid'
import notification from './notification'
import settings from './settings'
import user from './user'
import wallet from './wallet'
import npsSurvey from './npsSurvey'
import thriveGame from './thriveGame'

export const reducers = {
    auth,
    user,
    hybrid,
    settings,
    announcement,
    notification,
    achievement,
    wallet,
    garden,
    globalAppVariables,
    breadcrumbs,
    npsSurvey,
    thriveGame
}

export * from './achievement'
export * from './announcement'
export * from './auth'
export * from './breadcrumbs'
export * from './garden'
export * from './hybrid'
export * from './notification'
export * from './settings'
export * from './user'
export * from './wallet'
export * from './npsSurvey'
export * from './thriveGame'
