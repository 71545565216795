import { RumGlobal } from '@datadog/browser-rum'
import { getPlatform } from '../userAgent'
import store from '../../store'
import { LogsPublicApi } from '@datadog/browser-logs/cjs/boot/logsPublicApi'

/**
 * NOTICE: appName is added automically in thriveglobal/thrive-web-root
 * This can be found in the src/index.ejs file within that repo
 */

declare global {
    interface Window {
        DD_RUM: RumGlobal
        DD_LOGS: LogsPublicApi
    }
}

const getDataDogContext = () => {
    try {
        return {
            microFrontendVersions: window.__THRIVE__,
            thrivePlatform: getPlatform()
        }
    } catch (err) {
        console.log('DataDog Error: ', err)
    }
}
const setUser = () => {
    try {
        if (window?.DD_RUM?.getUser) {
            const state = store.getState()
            const ddUser = window?.DD_RUM?.getUser()

            if (ddUser?.userId !== state.user?.userId) {
                const user = {
                    id: state.user?.userId ?? null,
                    companyId: state.user?.companyId ?? null,
                    thriveAuthType: state.auth?.authType ?? null,
                    analyticsId: state.user?.analyticsId ?? null
                }
                window?.DD_RUM?.setUser(user)
                window?.DD_LOGS?.setUser(user)
            }
        }
    } catch (err) {
        console.log('DataDog Error: ', err)
    }
}

/**
 * Returns the DataDog Session Id
 *
 * @remarks
 * This function can be used to return a Session Id to link a session to a Replay within the DataDog platform
 *
 * @returns Session Id
 */
const getSessionId = () => {
    try {
        return (
            window?.DD_RUM?.getInternalContext?.()?.session_id ||
            'DataDog RUM not present in application'
        )
    } catch (err) {
        console.log('DataDog Error: ', err)
        return null
    }
}

/**
 * Manually report an error
 *
 * @remarks
 * This is used when an error is manually caught, important to note all thrown errors are caught by DataDog
 *
 * @param error - This will be an Error object with the thrown error
 * @param context - This is an optional object that can contain additional context about the error
 * @param teamName - This is used to identify which project an Error belongs to
 */
const captureException = (
    error: Error,
    context: object = {},
    teamName = 'none'
) => {
    setUser()
    const reportedError = typeof error === 'string' ? new Error(error) : error

    try {
        window?.DD_RUM?.addError(reportedError, {
            thriveApp: teamName,
            ...getDataDogContext(),
            ...context
        })
    } catch (err) {
        console.error('DataDog Not Present in Application')
    }
    try {
        window?.DD_LOGS?.logger.error(
            reportedError.message,
            {
                thriveApp: teamName,
                ...getDataDogContext(),
                ...context
            },
            reportedError
        )
    } catch (err) {
        console.error('DataDog Logger Not present')
    }
}

/**
 * Used to capture an Event of message
 *
 * @remarks
 * This will be used to replace captureMessage
 *
 * @param name - Name of the Action taken e.g. SplashClicked
 * @param context - This is an optional object that can contain additional context about the action
 * @param teamName - This is used to identify which project an Action belongs to
 */
const captureMessage = (
    name: string,
    context: object = {},
    teamName = 'none'
) => {
    setUser()

    try {
        window?.DD_RUM?.addAction(name, {
            thriveApp: teamName,
            ...getDataDogContext(),
            ...context
        })
    } catch (err) {
        console.error('DataDog Not Present in Application')
    }
    try {
        window?.DD_LOGS?.logger.info(name, {
            thriveApp: teamName,
            ...getDataDogContext(),
            ...context
        })
    } catch (e) {
        console.error('DataDog Logger Not present')
    }
}

/**
 * Reports the time from page load to when an Action has occured
 * This can be used for checking delays between page load and a component or image appearing
 *
 * @example Time for a splash image to load
 * ```html
 * <img onload="DD.captureTiming('splash_load')" src="/path/to/splash_image.png" />
 * ```
 *
 * @param timingName - Name of the timing event
 * @param customTiming - The number of milliseconds relative to the start of the current RUM view
 */
const captureTiming = (timingName: string, customTiming?: number) => {
    setUser()
    try {
        customTiming
            ? window?.DD_RUM?.addTiming(timingName, customTiming)
            : window?.DD_RUM?.addTiming(timingName)
    } catch (err) {
        console.error('DataDog Not Present in Application')
    }
}

/**
 * Adds metadata to the global context, which will be forward to Datadog RUM events and logs for filtering
 *
 * @example Add a metadata key and value
 * ```html
 * addMetadata('key', 'value')
 * ```
 *
 * @param key - key of the metadata
 * @param value - value of the metadata, this can be any datatype
 */
const addMetadata = (key: string, value: any) => {
    try {
        window?.DD_LOGS?.setGlobalContextProperty(key, value)
        window?.DD_RUM?.setGlobalContextProperty(key, value)
    } catch (err) {
        console.error('Datadog error: ', err)
    }
}

/**
 *  Adds a temporary user id to the global context. Used in apps where a user has not signed in or we do not know their user id
 *
 * @param unAuthenticatedUserId - anon user id that user can provide to Thrive Support
 */
const addUnAuthenticatedUserId = (unAuthenticatedUserId: string) => {
    try {
        window?.DD_LOGS?.setGlobalContextProperty(
            'unAuthenticatedUserId',
            unAuthenticatedUserId
        )
        window?.DD_RUM?.setGlobalContextProperty(
            'unAuthenticatedUserId',
            unAuthenticatedUserId
        )
    } catch (err) {
        console.log('DataDog Error: ', err)
    }
}

/**
 *  Removes temporary user id from the global context. Used in apps where a user has not signed in or we do not know their user id
 *
 */
const removeUnAuthenticatedUserId = () => {
    try {
        window?.DD_LOGS?.removeGlobalContextProperty('unAuthenticatedUserId')
        window?.DD_RUM?.removeGlobalContextProperty('unAuthenticatedUserId')
    } catch (err) {
        console.log('DataDog Error: ', err)
    }
}

/**
 *  Adds the users email domain to the global context
 *
 * @param emailDomain - email domain the user used to sign in
 */
const addUserEmailDomain = (emailDomain: string) => {
    try {
        window?.DD_LOGS?.setGlobalContextProperty(
            'userEmailDomain',
            emailDomain
        )
        window?.DD_RUM?.setGlobalContextProperty('userEmailDomain', emailDomain)
    } catch (err) {
        console.log('DataDog Error: ', err)
    }
}

/**
 *  Removes the users email domain from the global context
 *
 * @param emailDomain - email domain the user used to sign in
 */
const removeUserEmailDomain = () => {
    try {
        window?.DD_LOGS?.removeGlobalContextProperty('userEmailDomain')
        window?.DD_RUM?.removeGlobalContextProperty('userEmailDomain')
    } catch (err) {
        console.log('DataDog Error: ', err)
    }
}

/**
 *  Adds a feature flag to the global context for product analytics
 *
 * @param feature - feature flag name
 * @param value - value of the feature flag
 */
const addFeatureFlag = (feature: string, value: any | string) => {
    try {
        window?.DD_RUM?.addFeatureFlagEvaluation?.(feature, value)
    } catch (err) {
        console.error('Datadog error: ', err)
    }
}

export {
    addFeatureFlag,
    addMetadata,
    captureException,
    captureMessage,
    captureTiming,
    getSessionId,
    // auth tracking
    addUnAuthenticatedUserId,
    removeUnAuthenticatedUserId,
    addUserEmailDomain,
    removeUserEmailDomain
}
