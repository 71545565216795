import { captureException, initializeSegment, setupConfigCat } from './utils'
import { attemptToSetLanguage } from './utils/Intl/attemptToSetLanguage'

export async function ThriveAppInit() {
    // This function will run once at app startup.
    // It is the great initalizer for the app. We can set up things here that we only want one of
    console.log('THRIVE INITIALIZER')
    try {
        attemptToSetLanguage()
    } catch (error) {
        captureException(error, {
            message: 'ThriveAppInit failed to set language tag'
        })
    }

    try {
        // Trys to sets up config cat
        setupConfigCat()
    } catch (e) {
        captureException(e, {
            message: 'ThriveAppInit failed to setup config cat'
        })
    }

    // Initialize Segment
    try {
        await initializeSegment()
    } catch (e) {
        captureException(e, {
            message: 'ThriveAppInit failed to setup segment'
        })
    }
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: {
            app: string
            created: string
            version: string
        }[]
        analytics: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
