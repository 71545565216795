import * as configcat from 'configcat-js'
import type { IConfigCatClient } from 'configcat-common'
import store from '../../store'
import { getPlatform } from '../userAgent'
import { captureException } from '..'

// These window globals come from thrive-web-root
declare global {
    interface Window {
        getThriveClient: () => string
        cc_js: typeof configcat
        cc_client: IConfigCatClient
    }
}

export function setupConfigCat() {
    if (window.cc_client === undefined) {
        try {
            const configCatSdkKey = process.env.CONFIG_CAT
            const configCatLogLevel = Number(process.env.CONFIG_CAT_LOG_LEVEL)
            const logger = window.cc_js.createConsoleLogger(configCatLogLevel)

            window.cc_client = window.cc_js.getClient(
                configCatSdkKey,
                window.cc_js.PollingMode.AutoPoll,
                { logger, requestTimeoutMs: 10000 }
            )
        } catch (err) {
            captureException(new Error(`Config Cat Setup Failed ${err}`))
        }
    }
}

export interface CustomTargetingFieldsType {
    [key: string]: any
}

function configCatUser(extraCustomFields?: CustomTargetingFieldsType) {
    const state = store.getState()

    return {
        identifier: state.user.userId,
        custom: {
            companyId: state.user.companyId,
            client: window?.getThriveClient?.(),
            locale: state.settings.locale,
            platform: getPlatform(),
            subscriptionId: state.auth.subscriptionId,
            ...extraCustomFields
        }
    }
}

export async function isFeatureEnabled(
    feature,
    defaultValue: string | boolean = false,
    checkUser = false,
    extraCustomFields?: CustomTargetingFieldsType
): Promise<Boolean | string> {
    if (!window.cc_client) setupConfigCat()

    try {
        if (checkUser) {
            const user = configCatUser(extraCustomFields)
            return await window.cc_client.getValueAsync(
                feature,
                defaultValue,
                user
            )
        } else {
            return await window.cc_client.getValueAsync(feature, defaultValue)
        }
    } catch (err) {
        return false
    }
}

export async function getFeatureConfig<T>(
    feature,
    defaultValue,
    checkUser = false,
    extraCustomFields?: CustomTargetingFieldsType
): Promise<T> {
    if (!window.cc_client) setupConfigCat()

    try {
        if (checkUser) {
            try {
                const user = configCatUser(extraCustomFields)
                const data = await window.cc_client.getValueAsync(
                    feature,
                    defaultValue,
                    user
                )
                return JSON.parse(data)
            } catch (e) {
                console.error('could not parse config with user - ', e)
            }
        } else {
            try {
                const data = await window.cc_client.getValueAsync(
                    feature,
                    defaultValue
                )
                return JSON.parse(data)
            } catch (e) {
                console.error('could not parse config without user - ', e)
            }
        }
    } catch (err) {
        return null
    }
}
