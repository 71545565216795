import { useCallback } from 'react'
import { AuthState, UserState } from '../../store'
import { captureException } from '../../utils'

const method = 'POST'
const ZENDESK_VARIABLES = {
    API_TOKEN: process.env.ZENDESK_API_TOKEN,
    SUBDOMAIN: 'thriveglobal'
}
const headers = (contentType: string) => {
    return {
        'Content-Type': `${contentType}`,
        Authorization: `Basic ${ZENDESK_VARIABLES.API_TOKEN}`
    }
}
export const ZD_REQUEST_URL = `https://${ZENDESK_VARIABLES.SUBDOMAIN}.zendesk.com/api/v2/requests`
export const ZD_UPLOAD_URL = `https://${ZENDESK_VARIABLES.SUBDOMAIN}.zendesk.com/api/v2/uploads`

export type ZendeskResponse = {
    error: Error
    response: {}
}

export type ZendeskInformation = {
    comment: string
    subject: string
    userState: UserState
    authState: AuthState
    attachedFile?: File
}
export interface ZendeskRequestBody {
    request: {
        comment: {
            html_body: string
            uploads?: string[]
        }
        priority?: string
        subject: string
        requester: {
            name: string
            email: string
        }
        custom_fields: { id: number; value: string }[]
    }
}

const mapInfoToZendeskRequestShape = async (
    props: ZendeskInformation
): Promise<ZendeskRequestBody> => {
    const { comment, subject, attachedFile, userState, authState } = props

    const generatedToken =
        attachedFile && (await generateUploadToken(attachedFile))

    const customFieldTemp = `
    
    Custom fields -
    Thrive Company ID: ${userState.companyId}
    Email Address: ${userState.email}
    Thrive User ID:  ${userState.userId}
    Thrive Subscription ID: ${authState.subscriptionId}
    User Region: ${userState.userRegion}
    Group Code: ${userState.userSignupCode}`

    return {
        request: {
            comment: {
                html_body: comment.concat(customFieldTemp),
                ...(generatedToken ? { uploads: [generatedToken] } : {})
            },
            priority: 'normal',
            requester: {
                name: userState.fullName,
                email: userState.email
            },
            subject: subject,
            // Temporarily dumping these into ticket body
            // due to a Zendesk API issue that we're resolving
            custom_fields: [
                // Email Address - 19417807597331
                { id: 19417807597331, value: userState.email },

                // Thrive Company ID - 19835000702483
                { id: 19835000702483, value: userState.companyId },

                // Thrive Subscription ID - 23220983832339
                { id: 23220983832339, value: authState.subscriptionId },

                // Thrive User ID - 23220973514131
                { id: 23220973514131, value: userState.userId },

                // Group Code - 23438134420883
                { id: 23438134420883, value: userState.userSignupCode },

                // User Region - 23438016945043
                { id: 23438016945043, value: userState.userRegion }
            ]
        }
    }
}

// Generates token is used to attach the upload in a comment on the ticket
const generateUploadToken = async (attachedFile: File): Promise<string> => {
    const response: string = await fetch(
        `${ZD_UPLOAD_URL}?filename=${attachedFile.name}`,
        {
            method: method,
            headers: headers('application/binary'),
            body: attachedFile
        }
    )
        .then((res) => res.json())
        .then((responseData) => responseData?.upload?.token)
        .catch((err) => {
            const error = new Error('Error uploading attachment')
            captureException(err, {
                message: `Failure uploading attachment.`
            })
            return {
                error: error.message
            }
        })
    return response || ''
}

const generateZendeskBody = async (
    props: ZendeskInformation
): Promise<RequestInit> => {
    const { comment, subject, attachedFile, userState, authState } = props
    const requestBody = await mapInfoToZendeskRequestShape({
        comment,
        subject,
        attachedFile,
        userState,
        authState
    })
    return {
        method: method,
        headers: headers('application/json'),
        body: JSON.stringify({ ...requestBody })
    }
}

export const useZendeskReporter = () => {
    const submitRequest: (
        props: ZendeskInformation
    ) => Promise<ZendeskResponse> = useCallback(
        ({
            comment,
            subject,
            attachedFile,
            userState,
            authState
        }: ZendeskInformation) =>
            generateZendeskBody({
                comment,
                subject,
                attachedFile,
                userState,
                authState
            })
                .then((res) => fetch(ZD_REQUEST_URL, res))
                .then((res) => res.json())
                .then((responseData) => {
                    return {
                        response: responseData?.request || null,
                        error: responseData?.error || null
                    }
                })
                .catch((err) => {
                    const error = new Error('Error creating request.')
                    captureException(err, {
                        message: `Failure creating new request.`
                    })
                    return { response: null, error: error }
                }),
        []
    )
    return { submitRequest }
}

export default useZendeskReporter
