import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const THRIVE_GAME_SLICE_NAME = 'thriveGame'

export type GameAttributes = {
    id: string | null
}

export type UserProgress = {
    currentLevel: number
    nextLevel: number | null

    currentPoints: number
    currentLevelPointsRequired: number
    nextLevelPointsRequired: number | null

    isGameCompleted: boolean
}

export type ThriveGameState = {
    userProgress: UserProgress
    gameAttributes: GameAttributes
}

const INITIAL_STATE: ThriveGameState = {
    userProgress: {
        currentLevel: 1,
        nextLevel: null,

        currentPoints: 0,
        currentLevelPointsRequired: 0,
        nextLevelPointsRequired: null,

        isGameCompleted: false
    },

    gameAttributes: {
        id: null
    }
}

export const thriveGameSlice = createSlice({
    name: THRIVE_GAME_SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setUserProgress: (state, { payload }: PayloadAction<UserProgress>) => {
            state.userProgress = payload
        },

        setGameAttributes: (
            state,
            { payload }: PayloadAction<GameAttributes>
        ) => {
            state.gameAttributes = payload
        }
    }
})

export default thriveGameSlice.reducer
