import QRCode from 'qrcode'
import { useBranch } from '../useBranch'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../store'
import { type ApolloError } from '@apollo/client'
import { BranchLink } from '../useBranchLink'
import {
    GetSubscriptionGroupCodesQuery,
    SubscriptionType,
    useGetSubscriptionGroupCodesLazyQuery
} from '../../graphql/generated/autogenerated'
import {
    captureException,
    getBranchLink,
    getFriendsAndFamilyGroupCode,
    getUserSubscriptionGroupCode,
    isMicrosoftTeamsTest
} from '../../utils'
import { useGenerateTeamsDeepLink } from '../useGenerateTeamsDeepLink'
import { useGenerateTeamsUnfurlLink } from '../useGenerateTeamsUnfurlLink'

const getCanonicalUrl = (
    company: string,
    referrerId: string,
    isFriendsAndFamily: string,
    groupCode?: string,
    redirectUrl?: string
) => {
    const referralUrl = new URL(process.env.REFERRAL_LINK)
    const queryParams = new URLSearchParams({
        company,
        referrerId,
        isFriendsAndFamily
    })
    groupCode && queryParams.append('groupCode', groupCode)
    redirectUrl && queryParams.append('redirectUrl', redirectUrl)

    referralUrl.search = queryParams.toString()
    const finalReferralUrl = referralUrl.toString()
    return finalReferralUrl
}

export const useBranchReferralLink = (
    companyName: string,
    referrerName?: string,
    redirectUrl?: string,
    useTeamsUnfurlLink?: boolean
) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error | ApolloError>(null)

    const {
        branch,
        error: branchInitializeError,
        branchInitialized
    } = useBranch()

    const generateTeamsDeepLink = useGenerateTeamsDeepLink()
    const generateTeamsUnfurlLink = useGenerateTeamsUnfurlLink()

    const encodedCompany = encodeURIComponent(companyName)
    const encodedUrl = redirectUrl ? encodeURIComponent(redirectUrl) : ''
    const { companyId, userId } = useAppSelector((state) => state.user)

    const [isFnF, setIsFnF] = useState<boolean>(false)
    const [coworkerReferral, setCoworkerReferral] = useState<BranchLink>({
        link: '',
        qrCode: ''
    })
    const [friendsAndFamilyReferral, setFriendsAndFamilyReferral] =
        useState<BranchLink>({ link: '', qrCode: '' })

    const [getLinks] = useGetSubscriptionGroupCodesLazyQuery({
        onCompleted: async (data: GetSubscriptionGroupCodesQuery) => {
            const userSubscription = data?.identity?.me?.subscription

            const companySubscriptonsAndGroupCodes =
                data?.identity?.referrals?.subscriptionGroupCodes?.items

            const inFnFSub =
                userSubscription?.subscriptionType ===
                SubscriptionType.FriendsAndFamily

            try {
                const defaultCode = getUserSubscriptionGroupCode(
                    companySubscriptonsAndGroupCodes,
                    userSubscription.id
                )
                const friendsAndFamilyCode = getFriendsAndFamilyGroupCode(
                    companySubscriptonsAndGroupCodes
                )

                const linkData = {
                    companyName,
                    referrerId: userId,
                    redirectUrl,
                    $desktop_url: process.env.REFERRAL_LINK
                }

                const coworkerLinkData = {
                    tags: [`referral_${companyName}`],
                    data: {
                        ...linkData,
                        isFriendsAndFamily: false,
                        groupCode: defaultCode,
                        $canonical_url: getCanonicalUrl(
                            encodedCompany,
                            userId,
                            'false',
                            defaultCode,
                            encodedUrl
                        )
                    }
                }

                const friendsAndFamilyLinkData = {
                    tags: [`referral_${companyName}`],
                    data: {
                        ...linkData,
                        isFriendsAndFamily: true,
                        groupCode: friendsAndFamilyCode,
                        $canonical_url: getCanonicalUrl(
                            encodedCompany,
                            userId,
                            'true',
                            friendsAndFamilyCode,
                            encodedUrl
                        )
                    }
                }

                if (isMicrosoftTeamsTest()) {
                    let path = `profile/invite/teams/${userId}`
                    if (redirectUrl) {
                        path += redirectUrl
                    }
                    const teamsLink = (
                        useTeamsUnfurlLink
                            ? generateTeamsUnfurlLink
                            : generateTeamsDeepLink
                    )(path)
                    setCoworkerReferral({
                        link: teamsLink?.toString(),
                        qrCode: ''
                    })
                } else {
                    const defaultLink = await getBranchLink(
                        branch,
                        inFnFSub ? friendsAndFamilyLinkData : coworkerLinkData
                    )
                    const defaultQrCode =
                        (defaultLink &&
                            (await QRCode.toDataURL(defaultLink))) ||
                        ''

                    setCoworkerReferral({
                        link: defaultLink,
                        qrCode: defaultQrCode
                    })
                }

                if (!inFnFSub) {
                    const friendsAndFamilyLink =
                        friendsAndFamilyCode &&
                        (await getBranchLink(branch, friendsAndFamilyLinkData))

                    const friendsAndFamilyQrCode =
                        (friendsAndFamilyLink &&
                            (await QRCode.toDataURL(friendsAndFamilyLink))) ||
                        ''

                    friendsAndFamilyLink &&
                        setFriendsAndFamilyReferral({
                            link: friendsAndFamilyLink,
                            qrCode: friendsAndFamilyQrCode
                        })
                }
            } catch (err) {
                setError(err)
                captureException(err, null, 'identity')
            }
            setIsFnF(inFnFSub)
            setLoading(false)
        },
        onError: (err) => {
            setError(err)
            setLoading(false)
        }
    })

    useEffect(() => {
        // hook props can sometimes be undefined for a period of time
        // due to data fetching, mounting order, etc...
        const checkValuesAndSetError = () => {
            if (!(branchInitialized && companyName && userId && companyId)) {
                //if the required values don't resolve within 5sec
                //show an error
                setLoading(false)
                const paramError = new Error(
                    'Missing required parameters for generating links'
                )
                setError(paramError)
                captureException(paramError, null, 'identity')
            }
        }
        const timerId = setTimeout(checkValuesAndSetError, 5000)
        return () => clearTimeout(timerId)
    }, [branchInitialized, companyName, companyId, userId])

    useEffect(() => {
        if (branchInitialized && companyName && userId && companyId) {
            getLinks()
        }
    }, [branchInitialized, companyName, companyId, userId, getLinks])

    return {
        loading,
        error: error || branchInitializeError,
        coworkerReferral,
        friendsAndFamilyReferral,
        isFnF
    }
}
